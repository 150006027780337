<template>
  <div class="grid grid-cols-12 h-full">
    <div
      class="categories h-full col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2 border-r"
    >
      <ul class="p-4 font-light">
        <li class="p-2 text-xs text-gray-400">{{ title }}</li>
        <li class="mb-2" v-for="nl in sidebarLinks" :key="nl.title">
          <router-link
            class="block px-2 py-1 text-sm rounded"
            :to="nl.to"
            :class="setClass(nl.to)"
          >
            {{ nl.title }}
          </router-link>
        </li>
      </ul>
    </div>
    <div class="col-span-6 md:col-span-8 lg:col-span-9 xl:col-span-10 h-full">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    sidebarLinks: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentLocation() {
      return this.$route;
    },
  },
  methods: {
    setClass(to) {
      const resolved = this.$router.resolve(to);
      if (this.currentLocation.path.includes(resolved.path)) {
        return "router-link-active";
      }
    },
  },
};
</script>

<style lang="scss">
.categories {
  @apply h-full;
  //height: calc(100vh - 60px);
  li a.router-link-active,
  li a.router-link-exact-path-active,
  li a.router-link-exact-active {
    background-color: #446bea;
    color: #fff;
    font-weight: normal;
  }
}
</style>
