<template>
  <header class="flex items-center justify-between p-6 border-b">
    <div>
      <h1 class="text-3xl font-semibold">
        Аналитика <i v-if="loading" class="el-icon-loading"></i>
      </h1>
      <div class="mt-1 text-sm font-light text-gray-700">Сводка</div>
    </div>
    <div class="flex w-full justify-end flex-wrap gap-2">
      <el-date-picker
        @change="getAnalytics"
        v-model="filters.date"
        value-format="YYYY-MM-DD"
        type="daterange"
        size="large"
        style="max-width: 240px"
      ></el-date-picker>
      <el-select
        v-model="filters.annotator"
        @change="getAnalytics"
        placeholder="Выберите пользователя"
        filterable
        clearable
        size="large"
        style="max-width: 240px"
      >
        <el-option
          v-for="user in users"
          :key="user._id"
          :value="user._id"
          :label="`${user.first_name} ${user.last_name}`"
        ></el-option>
      </el-select>
      <el-select
        v-model="filters.status"
        @change="getAnalytics"
        placeholder="Выберите статус"
        clearable
        filterable
        size="large"
        style="max-width: 240px"
      >
        <el-option
          v-for="(val, key) in statuses"
          :key="key"
          :value="key"
          :label="val.title"
        ></el-option>
      </el-select>
    </div>
  </header>

  <main class="p-6">
    <el-row :gutter="20">
      <el-col :span="6" class="mb-5">
        <div
          class="summary border inline-block rounded w-full p-2 bg-gray-50 h-full text-green-400"
        >
          <div class="text-lg mb-3 font-semibold text-gray-400">
            {{ total_files.title }}
          </div>

          <div class="font-bold text-lg">
            {{ total_files.count || 0 }} файл(а/ов)
          </div>
        </div>
      </el-col>
      <el-col :span="6" class="mb-5">
        <div
          class="summary border inline-block rounded w-full p-2 bg-gray-50 h-full text-blue-400"
        >
          <div class="text-lg mb-3 font-semibold text-gray-400">
            {{ payed_files.title }}
          </div>

          <div class="font-bold text-lg">
            {{ payed_files.count || 0 }} файл(а/ов)
          </div>
        </div>
      </el-col>
      <el-col v-for="s in analytics" :key="s.title" :span="6" class="mb-5">
        <div
          class="summary border inline-block rounded w-full p-2 bg-gray-50 h-full"
        >
          <div class="text-lg mb-3 font-semibold text-gray-400">
            {{ s.title }}
          </div>

          <div class="font-bold text-lg">
            {{ getHourAndMinutes(s.seconds || 0) }}
          </div>
          <div class="text-xs text-gray-400">{{ s.count }} файл(а/ов)</div>
        </div>
      </el-col>
    </el-row>
  </main>
</template>

<script>
import { getOverallAnalytics } from "@/api/analytics";
import { getSection } from "@/views/files/shared/sections";
import { statuses } from "@/views/files/shared/statuses";
import { getUsers } from "@/api/users";

export default {
  name: "Home",
  titles: {
    op_tr: "Разметка оператора",
    user_tr: "Разметка пользователя",
    total: "Разметка общая",
  },
  data() {
    return {
      loading: false,
      statuses: statuses,
      users: [],
      analytics: null,
      total_files: {
        title: "Все файлы",
        count: 0,
      },
      payed_files: {
        title: "Оплаченные файлы",
        count: 0,
      },
      filters: {
        status: null,
        annotator: null,
        date: [],
      },
    };
  },
  async mounted() {
    await this.getAnalytics();
    this.users = await getUsers();
  },
  methods: {
    async getAnalytics() {
      this.loading = true;
      let params = { ...this.filters };
      delete params.date;
      if (this.filters.date) {
        params.start_date = this.filters.date[0];
        params.stop_date = this.filters.date[1];
      }
      let data = await getOverallAnalytics(params);
      this.analytics = data.totals;
      this.total_files = {
        count: data.total_files,
        title: "Все файлы",
      };
      this.payed_files = {
        count: data.payed_files,
        title: "Оплаченные файлы",
      };
      this.loading = false;
    },
    getStatusTitle(status) {
      const section = getSection(status);
      return section.title;
    },
    getHourAndMinutes(seconds) {
      const minutes = seconds / 60;
      const hours = Math.floor(minutes / 60);
      const minutes_ = Math.round(minutes - hours * 60);
      return `${hours} час(а/ов), ${minutes_} минут`;
    },
  },
};
</script>
